<template>
  <common-form
    ref="form"
    url="web/camera"
    :edit="edit"
    :items="items"
    @success="$emit('success')"></common-form>
</template>
<script>
  export default {
    data() {
      return {
        edit:false,
        iotId:""
      }
    },
    computed:{
      items() {
        return [
          {
            type: "input",
            name: "名称",
            key: "name",
            required: true,
          },
          {
            type: "textarea",
            name: "直播地址",
            key: "link",
            required: true,
          },
        ]

      }
    },

    methods: {
      show(model) {
        this.edit = model.id>0
        this.$refs.form.show(model)
      }
    }
  }
</script>
